@import "@assets/style/breakpoints.scss";

.bedroom_content {
  @include mobile_media_query {
    margin-top: 4px;
  }
  .title-container {
    .title {
      font-size: 64px !important;
      letter-spacing: 0.01em !important;
      @include mobile_media_query {
        line-height: 48px;
        letter-spacing: 0.01em;
        font-size: 32px !important;
      }
      @include mobile_smallest_query {
        font-size: 28px !important;
        line-height: 38px !important;
      }
    }
  }

  .bedroom_container {
    width: 1056px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 60px;
    gap: 36px;

    @include mobile_media_query {
      margin-top: 16px;
      width: 342px;
      gap: 22px;
    }
    .btn.form_button.button {
      span {
        font-size: 32px;
        line-height: 20px;
        font-weight: 400;
      }
      @include mobile_media_query {
        height: 60px;
        span {
          font-size: 26px !important;
          line-height: 20px;
        }
      }
    }
  }
}

.bedroomCount_button {
  width: 180px !important;

  @include mobile_media_query {
    width: 160px !important;
  }
}

#bedroom_form{
  @include mobile_media_query {
    scroll-margin-top: 100px;
  }
}