@import "@assets/style/breakpoints.scss";
.datacard_wrapper {
  text-align: center;
  background-color: var(--white);
  width: auto;
  min-height: 422px;
  padding: 40px;
  border-radius: 6px;
  ion-button {
    height: 48px !important;
  }
  @include mobile_media_query {
    padding: 40px 0;
  }
  .card_main_title {
    @include mobile_media_query {
      text-align: left;
      display: block;
    }
  }

  .datacard_footer {
    display: flex;
    justify-content: center;
    margin-bottom: 8px;
    @include mobile_media_query {
      text-align: left;
    }
    hr.datacard_footer_divider {
      height: 1px;
      width: 100%;
      border: 0;
      border-top: 1px solid var(--grey200);
    }
  }
}
