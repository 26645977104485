@import "@assets/style/breakpoints.scss";
.hero_header {
  height: auto;
  padding: 20px 0;
  @include mobile_media_query {
    &.workContainer {
      width: 100%;
      max-width: 100%;
      padding: 0;
    }
  }
  .left_col {
    @include mobile_media_query {
      min-height: 60vh;
    }
    @include mobile_smallest_query {
      min-height: 45vh;
    }
  }
}

.result_container {
  .cards_list {
    padding-top: 60px;
    @include mobile_media_query {
      padding: 0;
    }

    .main_container {
      .left_col {
        @include desktop_big_media_query {
          padding-right: 16px;
        }
      }
      .right_col {
        @include desktop_big_media_query {
          padding-left: 16px;
        }
      }
    }

    .card {
      background-color: var(--white);
      border: 1px solid var(--grey100);
      border-radius: 6px;
      min-height: 422px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 30px;
      @include tablet_lg_media_query {
        border-radius: 0;
        margin-bottom: 30px;
        border: 0;
        border-bottom: 1px solid var(--divider);
      }
      @include desktop_big_media_query {
        border: 1px solid var(--grey100);
        border-radius: 6px;
      }
      @include mobile_media_query {
        border: 0;
        border-radius: 0;
        border-bottom: 1px solid var(--divider);
      }
      @include mobile_and_tablet_sm_media_query {
        border: 0;
        border-radius: 0;
        border-bottom: 1px solid var(--divider);
      }
    }

    .no_justify {
      justify-content: flex-start;
      align-items: flex-start;
      padding: 40px;
      @include tablet_lg_media_query {
        justify-content: flex-start;
        align-items: flex-start;
      }
      @include desktop_big_media_query {
        justify-content: flex-start;
        align-items: flex-start;
      }
      @include mobile_media_query {
        justify-content: flex-start;
        align-items: flex-start;
      }
      @include mobile_and_tablet_sm_media_query {
        justify-content: flex-start;
        align-items: flex-start;
      }
    }

    .map_card {
      height: 730px;
      display: flex;
      justify-content: center;
      align-items: center;
      &.map_wrapper {
        background-color: var(--grey200);
      }
    }

    .card_left {
      @include desktop_media_query {
        margin-right: 100px;
        max-width: 505px;
      }
    }
    .card_right {
      @include desktop_media_query {
        margin-left: 100px;
        max-width: 505px;
      }
    }
  }
  .testimonial_container {
    margin: 100px 0;
    ion-grid {
      display: flex;
      align-items: center;
    }
    @include mobile_media_query {
      ion-grid {
        height: 450px;
      }
    }
  }
}

.report-loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 50vh;

  .loading-text {
    font-size: 18px;
  }
}
