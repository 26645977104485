@import "./variables";
@import "./colors";

:root,
a,
h1,
h2,
h3,
h4,
h5,
h6,
p,
input,
textarea,
::placeholder {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: var(--grey900);
}

$box-shadow-primary: var(--box-shadow-primary);
* {
  box-sizing: border-box;
  padding: 0;
  font-family: "Inter", sans-serif;
  // font-weight: 400;
  // font-size: 16px;
}

html.md {
  --ion-default-font: "Inter", sans-serif;
}
.clearfix::after {
  display: block;
  clear: both;
  content: "";
}
.no_float {
  float: none !important;
}

.left_col {
  padding-right: 16px;
  &.left_col_full {
    @include ipadpro_max_width_media_query {
      padding-right: 0;
    }
  }
  @include ipadpro_max_width_media_query {
    padding-right: 0;
  }
}
.right_col {
  padding-left: 16px;
  &.right_col_full {
    @include ipadpro_max_width_media_query {
      padding-left: 0;
    }
  }
  @include ipadpro_max_width_media_query {
    padding-left: 0;
  }
}

html.md {
  --ion-default-font: "Inter", sans-serif;
}

.no_border {
  border: 0 !important;
}
.main_container {
  margin-bottom: 30px;
  padding: 0 24px;
  @include tablet_lg_media_query {
    margin-bottom: 0;
    padding: 0 40px;
  }
  @include desktop_big_media_query {
    padding: 0 24px;
  }
  @include mobile_media_query {
    padding: 0 24px;
  }
}

.fullContainer {
  max-width: 1920px;
  margin: 0 auto;
}
.workContainer {
  max-width: 1760px;
  margin: 0 auto;
  padding: 0;
  @include desktop_md_and_tablet_query {
    max-width: 1280px;
  }
  @include ipadpro_max_width_media_query {
    max-width: 1760px;
    padding: 0 24px;
  }
  @include tablet_to_desktop_query {
    max-width: 1050px;
  }
  @include md_to_lg_query {
    max-width: 1366px;
  }
}

.report_footer {
  padding: 0 80px !important;

  @include mobile_media_query {
    padding: 0 !important;
  }

  margin: 0 !important;
  max-width: 100%;
  box-shadow: 0px -4px 6px rgba(0, 0, 0, 0.12);
  .footer_container {
    border-top-width: 0 !important;
    color: var(--grey600) !important;
  }
}

.contentContainer {
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 24px;
  @include tablet_to_desktop_query {
    max-width: 1050px;
  }
  @include md_to_lg_query {
    max-width: 1366px;
  }
}

@media (min-width: 1025px) {
  .d-lg {
    display: block;
  }
  .d-md {
    display: block;
  }
  .d-md-none {
    display: block !important;
  }
  .d-md-only {
    display: none;
  }
  .d-sm {
    display: none;
  }
  // display flex;
  .d-f-lg {
    display: flex;
  }
  .d-f-md {
    display: none;
  }
  .d-f-sm {
    display: none;
  }
}

@media (max-width: 1024px) {
  .d-lg {
    display: none;
  }
  .d-md {
    display: block;
  }
  .d-md-none {
    display: block !important;
  }
  .d-md-only {
    display: block;
  }
  .d-sm {
    display: none;
  }
  // display flex;
  .d-f-lg {
    display: flex;
  }
  .d-f-md {
    display: flex;
  }
  .d-f-sm {
    display: none;
  }
}
@media (max-width: 500px) {
  .d-lg {
    display: none;
  }
  .d-md {
    display: none;
  }
  .d-md-none {
    display: none !important;
  }
  .d-md-only {
    display: none;
  }
  .d-sm {
    display: block;
  }
  // display flex;
  .d-f-lg {
    display: none;
  }
  .d-f-md {
    display: none;
  }
  .d-f-sm {
    display: flex;
  }
}

/* HIDE SCROLLBAR BUT KEEP FUNCTIONALITY */
.hide-scrollbar {
  overflow-x: scroll;
}
/* Hide scrollbar for Chrome, Safari and Opera */
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hide-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.asterisk::after {
  content: "*";
  margin-left: 2px;
}

.error_border {
  @include tablet_and_desktop_media_query {
    border: 1px solid var(--danger) !important;
  }

  @include mobile_media_query {
    border: 1px solid var(--danger) !important;
    padding-left: 8px !important;
    margin-bottom: 4px !important;
    border-radius: 16px !important;
  }
}
/* *******************************************
              TYPOGRAPHY 
******************************************* */

// size

.text_xs {
  font-size: 12px !important;
}

.text_sm {
  font-size: 14px !important;
}

.text_base {
  font-size: 16px !important;
}

.text_lg {
  font-size: 18px !important;
}

.text_xl {
  font-size: 20px !important;
}

.text_2xl {
  font-size: 22px !important;
}

.text_3xl {
  font-size: 24px !important;
}

.text_4xl {
  font-size: 28px !important;
}

.text_5xl {
  font-size: 32px !important;
}

.text_6xl {
  font-size: 36px !important;
}

// weight

.font_thin {
  font-weight: 100 !important;
}

.font_extralight {
  font-weight: 200 !important;
}

.font_light {
  font-weight: 300 !important;
}

.font_normal {
  font-weight: 400 !important;
}

.font_medium {
  font-weight: 500 !important;
}

.font_semibold {
  font-weight: 600 !important;
}

.font_bold {
  font-weight: 700 !important;
}

.font_extrabold {
  font-weight: 800 !important;
}

.font_black {
  font-weight: 900 !important;
}

// remove background color from input while autofill
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 9999s ease-in-out 0s;
  -webkit-text-fill-color: #ffffff;
}