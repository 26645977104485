@import "@assets/style/breakpoints.scss";

.navbar {
  position: sticky;
  box-sizing: border-box;
  width: 100%;
  background: var(--white);
  box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.1);
  z-index: 999;
  top: 0;
  .workContainer {
    height: 90px;
    justify-content: space-between;
    display: flex;
    align-items: center;
    @include mobile_media_query {
      height: 60px;
      padding: 0px 20px;
    }
  }

  .pl_logo {
    height: "36px";
    width: "250px";
    cursor: pointer;
    @include mobile_media_query {
      height: 19px;
      width: 130px;
    }
  }

  .phone-number {
    text-decoration: none;
  }

  .nav_items_container {
    display: grid;
    grid-auto-flow: column;
    justify-content: flex-end;
    gap: 40px;
    flex: inherit;
    margin-left: auto;
    margin-right: 0;
    padding: 0;

    ion-grid {
      display: flex;
      align-items: center;
      padding: 0;
      cursor: pointer;
      @include ipadpro_max_width_media_query {
        display: none;
      }
    }

    .hamburger_menu {
      display: none;
      @include ipadpro_max_width_media_query {
        display: block;
      }
    }
  }
}

.sign_in_btn {
  --background: var(--white);
  color: black;
  border: 1px solid black;
  border-radius: 20px;
  span {
    font-weight: 600;
  }
}

.navbar_expanded {
  height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  animation: slideFromRight 0.2s forwards;
  transition: all 0.2s;
  z-index: 99999;
  background: var(--white);
  padding: 24px;
  .close_btn {
    position: absolute;
    right: 24px;
    top: 24px;
    cursor: pointer;
  }

  .expanded_menu_list {
    display: flex;
    flex-direction: column;
    gap: 40px;
    padding-top: 40px;
    ion-grid {
      display: contents;
      a {
        text-decoration: none;
      }
    }
  }

  .seperator {
    border: 1px solid var(--line-seperator);
    margin: 20px 0px;
  }

  .sign_in_btn {
    width: 50%;
  }
}

.black_curtain {
  height: 100vh;
  background: var(--black-curtain);
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 9990;
}

@keyframes slideFromRight {
  0% {
    width: 0px;
  }
  100% {
    width: 316px;
  }
}
