@import "@assets/style/variables.scss";

.list-property-container {
  background: var(--secondary-background-gradient);
  height: 100%;
  font-family: "Inter";
  color: white;
  padding: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @include mobile_media_query {
    justify-content: space-between;
    align-items: center;
    text-align: center;
    height: unset;
  }

  .heading-container {
    width: 100%;
    margin-bottom: 40px;
    display: flex;
    padding: 10px;
    justify-content: space-between;
    font-size: 16px;
    line-height: 115%;
    letter-spacing: 0.07em;
    text-transform: uppercase;
    @include mobile_media_query {
      margin-bottom: 10px;
      min-width: unset;
      max-width: unset;
      font-size: 18px;
      display: none;
    }
  }

  .adc-sub-heading {
    font-weight: 700;
    font-size: 27px;
    line-height: 107.4%;
    letter-spacing: 0.01em;
    text-transform: capitalize;
    width: 100%;
    @include mobile_media_query {
      margin: 20px 0;
      width: 100%;
      justify-content: center;
    }
  }
  .list-property-sub-container {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: auto;
    font-size: 1.5vw;
    @include mobile_media_query {
      width: 100%;
    }
  }
  .additional-details-container {
    height: 25%;
    font-size: 14px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    a {
      font-weight: 700;
      font-size: 14px;
      line-height: 107.4%;
      letter-spacing: 0.01em;
      margin-bottom: 16px;
      text-decoration: none;
    }

    .more_info {
      font-weight: 500;
      font-size: 14px;
      line-height: 140%;
      letter-spacing: 0.05em;
    }
    @include mobile_media_query {
      display: none;
    }
  }

  .button-style {
    --background: var(--primary-lighter) !important;
    --color: var(--black) !important;
    max-width: 200px;
    width: auto;
    height: 45px;
    border-radius: 10px;
    font-size: 20px;
    @include mobile_media_query {
      max-width: unset;
      width: 100%;
    }
  }
}
