@import "@assets/style/breakpoints.scss";

.propertyType {
  @include mobile_media_query {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  
  #propertyType_form {
    @include mobile_media_query {
      scroll-margin-top: 100px;
    }
  }

  .title {
    font-family: "Poppins", sans-serif;
    color: var(--white);
    font-weight: 500;
    font-size: 60px !important;
    line-height: 81px !important;
    letter-spacing: 0.01em !important;
    text-transform: capitalize;
    @include mobile_media_query {
      font-size: 32px !important;
      line-height: 48px !important;
    }
    @include mobile_smallest_query {
      font-size: 30px !important;
      line-height: 48px !important;
    }
  }

  .flex {
    display: flex;
    justify-content: center;
    gap: 40px;
    flex-wrap: wrap;
    margin-top: 60px;
    @include mobile_media_query {
      gap: 36px;
    }
    @include mobile_smallest_query {
      gap: 20px;
      margin-top: 30px;
    }

    ion-button span {
      font-size: 32px !important;
      font-weight: 400 !important;
      line-height: 20px !important;
      text-align: center;
      @include mobile_media_query {
        font-size: 26px !important;
      }
      @include mobile_smallest_query {
        font-size: 26px !important;
      }
    }
  }

  .wd_180 {
    width: 180px !important;
  }

  .selectedButton {
    --background: var(--white) !important;
    color: var(--form-btn-text);
    border: 1px solid var(--white);
  }

  .container {
    display: flex;
    justify-content: center;
    justify-content: space-around;
    width: 800px;
    gap: 20px;
    flex-wrap: wrap;
    margin-top: 60px;
  }

  .button-container {
    width: 800px;
    @include mobile_media_query {
      width: 100%;
    }
  }
}

#propertyType_form {
  @include mobile_media_query {
    scroll-margin-top: 100px;
  }
}