@import "@assets/style/breakpoints.scss";

/* ============== INPUTS =============== */
.input-wraper {
  display: contents;
  .label {
    color: var(--grey900);
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 8px;
    display: inline-block;
  }
  .inputs_mandatory {
    font-size: 16px;
    font-weight: 600;
    padding: 0px;
  }
  .input_error {
    color: var(--danger-light);

    @include mobile_media_query{
      margin-left: 20px;
      font-size: 18px;
    }
  }
  .input-block-fullwidth {
    width: 100%;
    --width: 100%;
    --min-height: 48px;
    height: 48px;
    text-indent: 10px;
    border: 1px solid var(--grey200);
    border-radius: var(--radius_8);
    --highlight-color-focused: unset;
    --highlight-color-valid: var(--success);
    --highlight-color-invalid: var(--danger);
    &.textarea-block {
      width: 320px;
      --width: 320px;
      --min-height: 170px;
      height: 170px;
      border: 1px solid var(--grey200);
      border-radius: var(--radius_8);
      --highlight-color-focused: unset;
      --highlight-color-valid: var(--success);
      --highlight-color-invalid: var(--danger);
    }
    &.ion-focusable.item-has-focus {
      border: 1px solid var(--grey900);
    }
    &.ion-focusable.item-has-focus.input-success {
      border: 1px solid var(--success);
    }
    &.ion-focusable.item-has-focus.input-warning {
      border: 1px solid var(--warning);
    }
    &.ion-focusable.item-has-focus.input-error {
      border: 1px solid var(--danger);
    }
    .input {
      --color: var(--grey900);
      color: var(--grey900);
    }
    .input::placeholder {
      --placeholder-color: var(--grey400);
      color: var(--grey400);
    }
    .phone-input input {
      padding-left: 0px !important;
    }
  }
  .input-block {
    --min-height: 48px;
    height: 48px;
    border-radius: var(--radius_8);
    --highlight-color-focused: unset;
    --highlight-color-valid: var(--success);
    --highlight-color-invalid: var(--danger);
    &.textarea-block {
      width: 320px;
      --width: 320px;
      --min-height: 170px;
      height: 170px;
      border: 1px solid var(--grey200);
      border-radius: var(--radius_8);
      --highlight-color-focused: unset;
      --highlight-color-valid: var(--success);
      --highlight-color-invalid: var(--danger);
    }
    &.ion-focusable.item-has-focus {
      border: 1px solid var(--grey900);
    }
    &.ion-focusable.item-has-focus.input-success {
      border: 1px solid var(--success);
    }
    &.ion-focusable.item-has-focus.input-warning {
      border: 1px solid var(--warning);
    }
    &.ion-focusable.item-has-focus.input-error {
      border: 1px solid var(--danger);
    }
    .input {
      --color: var(--grey900);
      color: var(--grey900);
    }
    .input::placeholder {
      --placeholder-color: var(--grey400);
      color: var(--grey400);
    }
  }
  .status-text {
    color: var(--grey600);
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.5px;
    margin-top: 8px;
    display: block;
    text-transform: none;
  }
  .upper-text {
    color: var(--grey600);
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.5px;
    margin-bottom: 16px;
    display: block;
  }
  .success-text,
  .warning-text,
  .error-text {
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.5px;
    margin-top: 8px;
    display: block;
  }
  .success-text {
    color: var(--success);
  }
  .warning-text {
    color: var(--warning);
  }
  .error-text {
    color: var(--danger);
  }
  .input-block .input-icon {
    color: var(--grey700);
    padding-left: 5px;
  }
  .input-block .search-icon {
    color: var(--grey400);
    width: 16px;
    padding-right: 16px;
  }
  .text-limit {
    margin-top: 8px;
  }
  .text-limit span {
    color: var(--grey600);
    font-weight: normal;
    font-size: 12px;
  }
}
