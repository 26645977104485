@import "@assets/style/breakpoints.scss";

.testimonials_slider {
  position: relative;
  text-align: left !important;

  .swiper-slide {
    text-align: left !important;
    justify-content: flex-start !important;
  }

  @include mobile_media_query {
    margin-left: 40px;
    margin-right: 40px;
  }
  @include desktop_media_query {
    .swiper-slide {
      width: 330px !important;
    }
  }
}

.swiper-pagination-bullet {
  width: 14px;
  height: 14px;
}
.swiper-pagination-bullet-active {
  background: var(--grey600);
}
.testimonials-title {
  font-weight: 500;
  font-size: 36px;
  line-height: 42px;
  letter-spacing: 0.01em;
  font-style: normal;
  text-transform: capitalize;
  margin-bottom: 40px;
  margin-left: 100px;
  @include mobile_media_query {
    margin-left: 24px;
  }
}
.testimonials_button {
  position: absolute;
  border-radius: 50%;
  height: 51px;
  width: 51px;
  background: var(--testimonial-btn-bg);
  padding: 10px;
  z-index: 99;

  @include mobile_media_query {
    background-color: transparent;
    height: unset;
    width: unset;
    border-radius: unset;
  }
}

.testimonials_button.prev {
  left: 60px;
  content: url("../../../assets/svg/left_arrow.svg");
  @include mobile_media_query {
    left: 10px;
    content: url("../../../assets/svg/left_arrow_thin.svg");
  }
}

.testimonials_button.next {
  right: 60px;
  content: url("../../../assets/svg/right_arrow.svg");
  @include mobile_media_query {
    right: 10px;
    content: url("../../../assets/svg/right_arrow_thin.svg");
  }
}

.prev_span {
  width: 80px;
  height: 90%;
  position: absolute;
  left: calc(100% - 80px);
  background: radial-gradient(#ffffff 40%, transparent);
  z-index: 9;
}
.next_span {
  width: 10%;
  height: 90%;
  position: absolute;
  right: calc(100vw - 6%);
  background: radial-gradient(#ffffff 30%, transparent);
  z-index: 9;
}
