.phone-input-comp {
  color: var(--grey900);

  .label {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 8px;
    display: inline-block;

    .inputs-mandatory {
      font-size: 16px;
      font-weight: 600;
      display: inline-block;
    }
  }
  .input-wraper .input-block-fullwidth {
    border: 0;
  }

  .phone-number-input {
    border: 1px solid var(--grey200);
    border-radius: 8px;
    padding: 0 16px;
    z-index: 9;
    background: var(--white);
    align-items: center;
    min-height: 48px;
    width: 100%;

    .phone-input-wrapper {
      display: flex;

      input {
        padding: 0 !important;
      }

      .input-field:focus,
      .input-field {
        border: none;
        outline: none;
        text-align: left;
      }

      /* Chrome, Safari, Edge, Opera */
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      /* Firefox */
      input[type="number"] {
        -moz-appearance: textfield;
      }

      .country-code {
        display: flex;
        cursor: pointer;

        .country-code-dropdown-text {
          display: flex;
          align-items: center;
        }
      }

      .country-code img {
        margin-right: 16px;
        margin-left: 8px;
      }
    }
  }

  .country-search-wrapper {
    background-color: var(--white);
    position: absolute;
    padding: 0 16px;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    z-index: 11;
    width: 100%;

    .country-search {
      padding: 8px 16px;
      display: block;
    }

    .dropdown-scroll {
      max-height: 220px;
      overflow-y: auto;
      scrollbar-width: thin;
      scrollbar-color: var(--white);

      &:hover {
        overflow: auto;
        scrollbar-width: thin;
        scrollbar-color: var(--grey900) var(--white);
      }

      &::-webkit-scrollbar {
        width: 5px;
        background: var(--white);
      }

      &:hover::-webkit-scrollbar {
        width: 5px;
      }

      &::-webkit-scrollbar-track {
        background: var(--white);
      }

      &::-webkit-scrollbar-thumb {
        background: var(--grey600);
        border-radius: 5px;
      }

      &::-webkit-scrollbar-thumb:hover {
        background: var(--grey700);
        width: 5px;
      }
    }

    .country-code-row {
      cursor: pointer;
      min-height: 36px;
      border-radius: 4px;
      padding: 8px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;

      &:hover {
        background-color: var(--grey50);
      }

      .country-code-text {
        color: var(--grey900);
        text-align: left;
        display: contents;
      }

      .country-name-text {
        color: var(--grey700);
        text-align: left;
        margin-left: 10px;
      }
    }
  }

  .ph-input-mrtp {
    margin-top: -14px;
  }

  .code-country {
    display: flex;
  }

  .code-mr {
    margin-top: 22px;
  }

  .img-mr {
    margin-top: 7px;
    padding-left: 3px;
    padding-right: 7px;
  }
}
