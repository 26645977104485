@import "@assets/style/variables.scss";

.banner-container {
  background: var(--primary-background-gradient);
  color: white;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  display: flex;
  align-items: center;
  @include mobile_media_query {
    height: 100%;
  }

  .banner-sub-container {
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 20px;
    @include desktop_large_media_query {
      width: 75%;
    }
    @include desktop_large_max_query {
      width: 100%;
    }

    .banner-row {
      display: flex;
      flex-direction: row;
      height: auto;
      align-items: center;
      justify-content: flex-start;
      padding: 20px;
      align-items: center;
      font-size: 1.4vw;
      @include mobile_media_query {
        padding: 20px 0px;
      }
    }
    .banner-title {
      font-size: 60px;
      line-height: 80px;
      font-weight: 500;
      letter-spacing: 0.01em;
      text-transform: capitalize;
      @include mobile_media_query {
        font-size: 40px;
        font-weight: 500;
        line-height: 60px;
      }
    }
    .banner-subtitle {
      font-size: 36px;
      line-height: 48px;
      letter-spacing: 0.01em;
      text-transform: capitalize;
      font-weight: 400;
      @include mobile_media_query {
        line-height: 38px;
        line-height: 39px;
        font-size: 26px;
      }
    }
  }
}
