@import "@assets/style/breakpoints.scss";
.rental-value-container {
  margin-top: 51px;
  @include mobile_media_query {
    text-align: left;
  }
  .rental-value {
    font-size: 80px;
    font-weight: 600;
    line-height: 115%;
    letter-spacing: 0.01em;
    text-transform: capitalize;
    @include mobile_media_query {
      font-size: 70px;
    }
  }
  .unknown-text {
    font-size: 60px;
  }
  .rental-value-subtext-container {
    margin-top: 10px;
    width: 307px;
    @include mobile_media_query {
      width: unset;
      text-align: left;
    }
    .rental-value-subtext {
      color: var(--grey400);
      font-size: 12px;
      font-weight: 600;
      line-height: 107.4%;
      letter-spacing: 0.01em;
      text-transform: capitalize;
      .phone-number {
        text-decoration: none;
      }
    }
  }
  .rental-value-button-container {
    display: flex;
    justify-content: space-around;
    padding: 20px 0px 20px 0px;
    .list-property-button {
      margin-right: 80px;
      @include mobile_media_query {
        margin: 20px 0 16px 0;
      }
    }
    .button-style {
      --background: var(--primary-btn-gradient) !important;
      --color: var(--white) !important;
      width: auto;
      height: 45px;
      border-radius: 10px;
      @include mobile_media_query {
        width: 100%;
      }
      &:first-child {
        @include mobile_media_query {
          margin-bottom: 24px;
        }
      }
    }
    .valuation-button-style {
      --color: var(--primary) !important;
      --background: var(--white) !important;
      border: 1px solid var(--primary) !important;
    }
    .valuation-button-style:hover {
      --background: var(--primary-btn-gradient) !important;
      --color: var(--white) !important;
      border: none;
    }
  }
}
