@import "@assets/style/breakpoints.scss";
.property-availability {
  @include mobile_media_query {
    width: 100%;
  }
  .loader {
    color: var(--white);
  }
  .title {
    font-size: 60px !important;
    font-family: "Poppins", sans-serif;
    color: var(--white);
    font-weight: 500;
    line-height: 108% !important;
    letter-spacing: 0.01em !important;
    text-transform: capitalize;
    @include mobile_media_query {
      font-size: 32px !important;
      line-height: 48px !important;
      letter-spacing: 0.01em !important;
    }
    @include mobile_smallest_query {
      font-size: 28px !important;
      line-height: 38px !important;
    }
  }

  .container {
    display: flex;
    justify-content: space-around;
    width: 800px;
    gap: 54px;
    flex-wrap: wrap;
    margin-top: 60px;
    @include mobile_media_query {
      flex-direction: column;
      gap: 29px;
      align-items: center;
      width: 330px;
      .btn {
        span {
          font-size: 26px !important;
          line-height: 20px !important;
        }
      }
    }
    @include mobile_smallest_query {
      width: 100%;
      gap: 32px;
      margin-top: 0;
    }

    .btn.form_button {
      span {
        font-size: 30px !important;
        font-weight: 400;
        line-height: 20px;
        @include mobile_smallest_query {
          font-size: 26px !important;
        }
      }
    }
  }
}

#availability_form {
  @include mobile_media_query {
    scroll-margin-top: 100px;
  }
}
