@import "@assets/style/breakpoints.scss";

.property-summary {
  margin-top: 60px;
  margin-bottom: 50px;
  .table-container {
    .table-value {
      padding: 1rem;
      @include mobile_media_query {
        padding: 0.5rem;
      }
      .summary-text {
        font-family: "Poppins";
        font-size: 20px;
        font-weight: 700;
        line-height: 21px;
        letter-spacing: 0.01em;
        text-align: center;
        letter-spacing: 0.01em;
        text-transform: capitalize;
        margin-bottom: 3px;
      }
      .summary-sub-text {
        font-family: "Poppins";
        font-size: 14px;
        font-weight: 500;
        line-height: 16px;
        letter-spacing: 0.01em;
        text-align: center;
      }
    }
  }
}
.hide_footer {
  display: none;
}
