@import "@assets/style/breakpoints.scss";

.grid_icon_wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    justify-content: space-around;

    @include tablet_lg_media_query {
        margin-bottom: 30px;
    }

    .grid_icon_col {
        min-width: 33.33%;
        margin: 12px 0 12px 0px;
        text-align: center;

        @include mobile_media_query {
            min-width: 50%;
        }
        @include tablet_media_query {
            min-width: 50%;
            margin-left: 0px;
        }
        
        .grid_icon {
            margin: auto;
            width: 55px;
            height: 55px;
        }

        .grid_icon_text {
            line-height: 40px;
            white-space: nowrap;
        }
    }
}