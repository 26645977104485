.tabular {
  .table_col:not(:last-child) {
    border-right: 1px solid var(--grey100);
  }
  .table_row:not(:last-child) {
    border-bottom: 1px solid var(--grey100);
  }
  .table_col {
    text-align: center;
  }
}
