@import "@assets/style/breakpoints.scss";

.form_bg {
  text-align: center;
  box-sizing: border-box;
  padding-top: 30px;

  @include ipadpro_max_width_media_query {
    padding: 20px;
    padding-top: 0;
  }
  @include md_to_lg_query {
    padding: 0px;
  }
  @include tablet_to_desktop_query {
    padding: 0px;
  }

  @include mobile_media_query {
    padding: 0px;
    align-items: flex-start;
    justify-content: flex-start;
    overflow-y: auto;
    flex-direction: column;
    height: calc(100vh - 120px);
  }

  &.footer_padding {
    @include mobile_media_query {
      height: calc(100vh - 212px);
    }
    @include mobile_smallest_query {
      height: calc(100vh - 200px);
    }
  }
}
