@import "@assets/style/breakpoints.scss";
.rental-range-container {
  margin-top: 51px;
  margin-bottom: 37px;
  .rental-range {
    font-weight: 600;
    padding: 20px;
    letter-spacing: 0.01em;
    text-transform: capitalize;
    display: flex;
    justify-content: center;
    align-items: center;
    @include mobile_media_query {
      padding: 0px;
    }
  }
  .rental-range-subtext-container {
    width: 307px;
    @include mobile_media_query {
      width: 100%;
      text-align: center;
      margin-top: 40px;
    }
    .rental-range-subtext {
      color: var(--primary);
      text-transform: capitalize;
      font-family: Inter;
      font-size: 16px;
      font-weight: 600;
      line-height: 20px;
      letter-spacing: 0em;
      text-align: center;
    }
  }
  .rental-range-text-container {
    display: flex;
    justify-content: space-around;
    font-size: 2vw;
    width: 90%;
    height: auto;
    @include mobile_media_query {
      width: 100%;
    }
    .rental-range-text-sub-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 10px;
      .rental-range-text {
        font-size: 32px;
        @include mobile_smallest_query {
          font-size: 30px;
        }
      }
    }
  }
  .rental-range-line {
    background-color: var(--primary);
    height: 7px;
    width: 90%;
    border-radius: 10px;
    @include mobile_media_query {
      width: 100%;
    }
    .rental-range-line-left {
      background-color: var(--primary);
      width: 25%;
      border-radius: 10px;
    }
    .rental-range-line-center {
      background-color: var(--danger);
      width: 50%;
    }
    .rental-range-line-right {
      background-color: var(--primary);
      width: 25%;
      border-radius: 10px;
    }
  }
}
