/*
-----------------------------

Mobile  =   0    -  500

Tablet:   
  Small =   501  -  800 
  Large =   801  -  1050 

Desktop =   1051 -  1920 

-------------------------------
max-width = less than or equal to
min-width = greater than or equal to
-------------------------------
*/

// variables
$mobile_sm_max_width: 391px;
$mobile_min_width: 392px;
$mobile_max_width: 500px;
$tablet_small_max_width: 800px;
$tablet_large_max_width: 1050px;
$desktop_medium_max_width: 1400px;
$desktop_large_max_width: 1920px;

@mixin mobile_media_query {
  @media (max-width: $mobile_max_width) {
    @content;
  }
}

@mixin mobile_media_query_with_min_width {
  @media (max-width: $mobile_max_width) and (min-width: $mobile_min_width) {
    @content;
  }
}

@mixin tablet_media_query {
  @media (min-width: #{$mobile_max_width + 1}) and (max-width: $tablet_large_max_width) {
    @content;
  }
}

@mixin tablet_sm_media_query {
  @media (min-width: #{$mobile_max_width + 1}) and (max-width: $tablet_small_max_width) {
    @content;
  }
}

@mixin tablet_lg_media_query {
  @media (min-width: #{$tablet_small_max_width + 1}) and (max-width: #{$tablet_large_max_width + 1}) {
    @content;
  }
}

@mixin tablet_sm_and_tablet_lg_media_query {
  @media (min-width: #{$tablet_small_max_width + 1}) and (max-width: $tablet_large_max_width) {
    @content;
  }
}
@mixin desktop_md_media_query {
  @media (min-width: #{$tablet_large_max_width + 1}) and (max-width: #{$desktop_medium_max_width - 1}) {
    @content;
  }
}

@mixin ipadpro_max_width_media_query {
  @media (max-width: #{$tablet_large_max_width + 1}) {
    @content;
  }
}
@mixin desktop_media_query {
  @media (min-width: #{$tablet_large_max_width + 1}) {
    @content;
  }
}

@mixin mobile_and_tablet_sm_media_query {
  @media (max-width: #{$tablet_small_max_width}) {
    @content;
  }
}
@mixin desktop_md_media_query {
  @media (min-width: #{$tablet_small_max_width}) {
    @content;
  }
}

@mixin tablet_and_desktop_media_query {
  @media (min-width: #{$mobile_max_width + 1}) {
    @content;
  }
}

@mixin desktop_large_media_query {
  @media (min-width: #{$desktop_large_max_width + 1}) {
    @content;
  }
}
@mixin desktop_large_max_query {
  @media (max-width: #{$desktop_large_max_width - 1}) {
    @content;
  }
}

// ony fo 1024px
@mixin desktop_big_media_query {
  @media (min-width: #{$tablet_large_max_width - 27}) and (max-width: #{$tablet_large_max_width - 23}) {
    @content;
  }
}
//medium desktop and tablet
@mixin desktop_md_and_tablet_query {
  @media (min-width: #{$mobile_max_width + 1}) and (max-width: #{$desktop_medium_max_width - 1}) {
    @content;
  }
}
@mixin mobile_smallest_query {
  @media (max-width: $mobile_sm_max_width) {
    @content;
  }
}
@mixin desktop_query {
  @media (min-width: $tablet_large_max_width) {
    @content;
  }
}
// between  1050 to 1920
@mixin tablet_to_desktop_query {
  @media (min-width: #{$tablet_large_max_width +1}) and (max-width: #{$desktop_medium_max_width - 1}) {
    @content;
  }
}

// between  1400 to 1920
@mixin md_to_lg_query {
  @media (min-width: #{$desktop_medium_max_width +1}) and (max-width: #{$desktop_large_max_width - 1}) {
    @content;
  }
}
@mixin desktop_tablet_query {
  @media (min-width: $mobile_max_width) {
    @content;
  }
}
