@import "@assets/style/breakpoints";
.areaNotCovered {
  .contentContainer {
    color: #000;
    margin-top: 65px;
    ion-col {
      margin: 0 auto;

      .anc_heading {
        font-size: 26px;
        font-weight: 600;
        line-height: 39px;
        letter-spacing: 0.01em;
        text-transform: capitalize;
        display: block;
        @include desktop_tablet_query {
          font-size: 26px;
        }
      }
      .anc_para {
        font-size: 18px;
        line-height: 28px;
        font-weight: 500;
        margin-top: 32px;
        display: block;
        @include desktop_tablet_query {
          font-size: 20px;
          line-height: 30px;
        }
        ul {
          margin-left: 20px;
          margin-bottom: 0;
          li {
            margin-bottom: 32px;
            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
      .anc_registered_block {
        margin-top: 90px;
        margin-bottom: 90px;
        text-align: center;
        .anc_reg_text {
          font-size: 24px;
          line-height: 36px;
          font-weight: 500;
          width: 95%;
          display: block;
          margin: 0 auto;
          text-transform: capitalize;
        }
        .anc_reg_btn {
          background: var(--primary-gradient);
          color: #fff;
          font-size: 18px;
          font-weight: 500;
          height: 48px;
          border-radius: 8px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 22px;
          text-transform: capitalize;
          cursor: pointer;
          @include desktop_tablet_query {
            width: 230px;
          }
        }
      }
    }
  }
}
