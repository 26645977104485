@import "@assets/style/breakpoints.scss";

.testimonial_card {
    display: flex;
    flex-direction: column;
    height: fit-content !important;
    min-height: 400px;
    padding: 40px;
    border: 1px solid var(--grey200);
    border-radius: 6px;
    background: var(--white);
    --background: var(--white);
    color: var(--grey300);

    @include mobile_media_query{
        margin-bottom: 60px;
        padding: 24px;

    }
    
    .testimonial_stars_wrapper {
        width: 100%;
        display: flex;
        flex-direction: row;

        .testimonial_stars {
            width: 18px;
            margin-right: 8px;
        }
    }

    .testimonials_author {
        width: 100%;
        font-style: italic;
        display: flex;
        flex-direction: row;

        .testimonial_dot {
            width: 6px;            
            margin-right: 8px;
        }
    }
}