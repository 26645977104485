@import "@assets/style/variables.scss";
.market_analysis {
  padding: 40px;
  @include mobile_media_query {
    padding: 0;
  }
  .title_container {
    display: flex;
    flex-direction: column;
    .title {
      font-size: 36px;
      line-height: 41.4px;
      color: black;
      margin-bottom: 16px;
      letter-spacing: 0.01em;
    }
    .subtitle {
      color: var(--primary);
      font-weight: 500;
      font-size: 22px;
      line-height: 20px;
    }
  }

  .main_content {
    display: flex;

    @include ipadpro_max_width_media_query {
      flex-direction: column-reverse;
    }

    .left {
      margin-top: 50px;
      .left_text_wrapper {
        width: 400px;
        margin: 0px;
        margin-bottom: 0.1em;
        @include mobile_media_query {
          width: unset;
        }
        ion-text {
          font-size: 22px;
          line-height: 33px;
          letter-spacing: 0.1em;
          color: var(--grey600);
        }
      }

      .btn_container {
        margin-top: 24px;
        display: flex;
        flex-direction: row;
        gap: 34px;
        @include mobile_media_query {
          flex-direction: column;
          gap: 24px;
          margin-bottom: 40px;
        }
        .btn {
          width: 230px;
          height: 48px;
          border-radius: 8px;
          background: var(--primary);
          --background: var(--primary);
          @include mobile_media_query {
            width: unset;
          }
        }
        .btn_outline {
          background: var(--white);
          color: var(--primary);
          --background: var(--white);
          border: 1px solid var(--primary);
        }
      }
    }
    .right {
      display: flex;
      align-items: flex-end;

      @include ipadpro_max_width_media_query {
        margin-top: 40px;
      }
      .table-value {
        padding: 1rem;
      }
      .summary-text {
        font-weight: 700;
        font-size: 20px;
        line-height: 107.4%;
      }
      .summary-sub-text {
        font-weight: 500;
        font-size: 14px;
        line-height: 115%;
        color: var(--grey300);
      }
    }
  }
}
