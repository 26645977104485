@import "@assets/style/breakpoints";

.btn {
  border-radius: var(--radius_8);
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  text-decoration: none;
  cursor: pointer;
  text-transform: capitalize;
  margin: 0;
  overflow: auto;

  &:hover {
    --border-radius: var(--radius_8);
  }

  span {
    font-size: 16px;
    letter-spacing: 0;
    font-weight: normal;
  }
}

.disabled {
  opacity: 0.7;
}

.btn.form_button,
.btn.form_button.button {
  font-family: "Inter", sans-serif;
  width: 359px;
  height: 71px;
  --background: transparent;
  border: 1px solid var(--white);

  &:hover {
    --background: var(--white-transparent-light);
    color: var(--form-btn-text);
    border: 1px solid var(--white-transparent-light);
  }

  span {
    font-size: 32px;
  }

  @include mobile_media_query {
    width: 330px;
  }
}

.btn.navigation_disabled,
.btn.navigation_disabled.button {
  width: 220px;
  font-family: "Inter", sans-serif;
  height: 48px;
  --background: var(--navigation-primary-bg);
  border: 1px solid var(--navigation-border);
  border-radius: 8px;

  &:hover {
    --background-hover: transparent;
  }

  span {
    font-size: 18px;
  }

  @include mobile_media_query {
    width: 160px;
  }
}

.btn.navigation,
.btn.navigation.button {
  width: 220px;
  font-family: "Inter", sans-serif;
  height: 48px;
  --background: transparent;
  border: 1px solid var(--navigation-border);

  &:hover {
    --background-hover: transparent;
  }

  span {
    font-size: 18px;
    font-weight: 600;
    line-height: 20px;
  }

  @include mobile_media_query {
    width: 160px;
  }
}

.navigation_main_container {
  @include mobile_media_query {
    width: 100%;
    height: 120px;
    position: fixed;
    left: 0;
    bottom: 0;
    background: var(--navigation-main-container);
    box-shadow: var(--navigation-main-container-shadow);
    z-index: 999;
  }
  @include mobile_smallest_query {
    height: 90px;
  }
}

.navigation_container {
  display: flex;
  width: 484px;
  margin-top: 5%;
  margin-bottom: 2%;
  justify-content: space-between;
  @include mobile_media_query {
    margin-top: 0;
  }
}

.selectedButton {
  --background: var(--white) !important;
  color: var(--form-btn-text);
  border: 1px solid var(--white);
}
