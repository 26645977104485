.achieving-percentage {
  margin-top: 105px;
  margin-bottom: 92px;
  .value-container {
    .percentage-value-text {
      font-weight: 500;
      font-size: 80px;
      line-height: 92px;
      letter-spacing: 0.01em;
    }
    .percentage-sub-text {
      margin-top: 6px;
      font-weight: 700;
      font-size: 22px;
      line-height: 24px;
      letter-spacing: 0.01em;
      text-transform: capitalize;
    }
  }
}
