@import "@assets/style/breakpoints.scss";

.property_sits_analysis {
  background-color: var(--white);
  width: auto;
  padding: 40px;
  border-radius: 6px;
  padding-bottom: 30px;
  @include mobile_media_query {
    padding: 0;
  }
  .table-value {
    padding: 1rem;
    .summary-text {
      line-height: 21px;
      letter-spacing: 0.01em;
      text-align: center;
      letter-spacing: 0.01em;
      text-transform: capitalize;
      margin-bottom: 3px;
    }
    .summary-sub-text {
      line-height: 16px;
      letter-spacing: 0.01em;
      text-align: center;
    }
  }
  .title_container {
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;
    .title {
      color: var(--black);
      margin-bottom: 16px;
      line-height: 41px;
      letter-spacing: 0.01em;
      text-align: left;
    }
    .subtitle {
      color: var(--primary);
      line-height: 20px;
    }
  }

  .left_text_wrapper {
    margin-top: 37px !important;
    margin-bottom: 0.1em;
    ion-text {
      line-height: 28px;
      text-align: left;
    }
  }

  .btn_container {
    margin-top: 9px;
    display: flex;
    flex-direction: row;
    gap: 34px;
    @include mobile_media_query {
      flex-direction: column;
      gap: 24px;
      margin-bottom: 40px;
      margin-top: 0px;
      padding: 20px 0;
    }
    .btn {
      width: 230px;
      @include mobile_media_query {
        width: unset;
      }

      height: 48px;
      border-radius: 8px;
      background: var(--primary);
      --background: var(--primary);
      @include mobile_media_query {
        width: unset;
      }
    }
    .btn_outline {
      background: var(--white);
      color: var(--primary);
      --background: var(--white);
      border: 1px solid var(--primary);
    }
  }
}
