.redirect-btn {
  font-family: "Nunito", sans-serif;
  display: inline-block;
  font-weight: 700;
  border-radius: 40px;
  text-decoration: none;
  color: var(--primary);
  cursor: pointer;
}

.error-details {
  color: var(--danger);
  max-height: 20vh;
  overflow: hidden;
  overflow-y: scroll;
}
