@import "@assets/style/variables.scss";
.tenant-number-container {
  margin-top: 51px;
  margin-bottom: 37px;
  @include mobile_media_query {
    text-align: center;
  }
  .tenant-number {
    font-size: 80px;
    font-weight: 500;
    line-height: 115%;
    letter-spacing: 0.01em;
    text-transform: capitalize;
  }
  .tenant-database-subtext-container {
    width: 307px;
    margin-top: 37px;
    @include mobile_media_query {
      width: unset;
    }
    .tenant-database-subtext {
      font-size: 22px;
      font-weight: 600;
      line-height: 107.4%;
      letter-spacing: 0.01em;
      text-transform: capitalize;
    }
  }
}
